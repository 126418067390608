
// Vue reactivity
import { computed, ref, watch } from "vue";

// icons
import { search } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonSearchbar, IonIcon, IonButtons, IonButton,
        IonSegment, IonSegmentButton, IonLabel, 
        IonGrid, IonRow, IonCol, IonSpinner, IonChip,
        IonSlides, IonSlide, } from "@ionic/vue";
import ProductCard from "@/components/ProductCard.vue";
import CartButton from "@/components/CartButton.vue";
import Slides from '@/components/Slides.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: "ProductListPage",
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
    IonSearchbar, IonIcon, IonButtons, IonButton,
    IonSegment, IonSegmentButton, IonLabel,
    IonGrid, IonRow, IonCol, IonSpinner, IonChip,
    IonSlides, IonSlide,
    ProductCard, CartButton, Slides
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const preSelectCategoryId: any = computed(() => router.currentRoute.value.query.category); // route paramters (mainly ID)

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const allProducts = computed(() => store.state.allProducts);
    const allProductCategories = computed(() => store.state.allProductCategories);
    const products = ref<any>(allProducts.value); // filtered products
    const showSearchBar = ref(false);
    const searchKeyword = ref("");
    const categoryFilter = ref("all");
    const categorySegmentBtns = ref([]);

    const featuredProducts = computed(() => store.getters.featuredProducts);

    const allProductTags = computed(() => store.getters.allProductTags(categoryFilter.value));
    const selectedTag = ref("all");

    // 2. watch changes of state variables
    const syncCategoryFilter = () => {
      if (categorySegmentBtns.value) {
        for (let i = 0; i < categorySegmentBtns.value.length; i++) {
          const el: any = categorySegmentBtns.value[i];
          if (el.value == categoryFilter.value) {
            setTimeout(() => {
              el.$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              });
            }, 100);
            break;
          }
        }
      }
    }
    watch(preSelectCategoryId, (currCategoryId: any) => {
      if (currCategoryId) {
        categoryFilter.value = currCategoryId;
        syncCategoryFilter();
      }
    })
    watch(allProducts, (currProducts, prevProducts) => { // triggered only when direct access to products page
      products.value = currProducts.filter((obj: any) => obj.title.toLowerCase().includes(searchKeyword.value));
    })
    watch(searchKeyword, (currKeyword, prevKeyword) => {
      const keyword = currKeyword.toLowerCase();
      products.value = allProducts.value.filter((obj: any) => obj.title.toLowerCase().includes(keyword));
    })

    // methods or filters
    const { t } = useI18n();

    const setSelectedTag = (tag = 'all') => {
      selectedTag.value = tag;
    }
    const onCategoryChanged = (e: any) => {
      setSelectedTag('all'); // reset selected tags
    }

    if (preSelectCategoryId.value) { // first enter
      categoryFilter.value = preSelectCategoryId.value;
      syncCategoryFilter();
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t,
      
      // icons
      search,
      
      // state variables
      loading, products, showSearchBar, searchKeyword, categoryFilter, allProductCategories,
      categorySegmentBtns, userLoggedIn,
      
      featuredProducts,
      allProductTags, selectedTag, setSelectedTag, onCategoryChanged,
    };
  },
};
