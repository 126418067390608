import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "horizontal-scroll" }
const _hoisted_2 = {
  key: 0,
  class: "spin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_cart_button = _resolveComponent("cart-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_slides = _resolveComponent("slides")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, {
            fixed: "",
            style: {"padding":"0"}
          }, {
            default: _withCtx(() => [
              ($setup.showSearchBar)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_searchbar, {
                        modelValue: $setup.searchKeyword,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchKeyword) = $event)),
                        "show-cancel-button": "always",
                        placeholder: "搜尋",
                        onIonCancel: _cache[1] || (_cache[1] = ($event: any) => ($setup.showSearchBar = false))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_toolbar, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_title, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.t('shop')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, { slot: "primary" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showSearchBar = true))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: $setup.search
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_cart_button)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_toolbar, { mode: "md" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment, {
                    modelValue: $setup.categoryFilter,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.categoryFilter) = $event)),
                    onIonChange: _cache[4] || (_cache[4] = ($event: any) => ($setup.onCategoryChanged($event))),
                    scrollable: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_segment_button, { value: "all" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.t('all')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allProductCategories, (category, i) => {
                        return (_openBlock(), _createBlock(_component_ion_segment_button, {
                          ref_for: true,
                          ref: el => { $setup.categorySegmentBtns[i] = el },
                          key: category.id,
                          value: category.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(category.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              ($setup.allProductTags.length > 0)
                ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                    key: 2,
                    mode: "md"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_ion_chip, {
                          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.setSelectedTag('all'))),
                          outline: $setup.selectedTag != 'all'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.t('all')), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["outline"]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.allProductTags, (tag) => {
                          return (_openBlock(), _createBlock(_component_ion_chip, {
                            key: tag,
                            onClick: ($event: any) => ($setup.setSelectedTag(tag.name)),
                            outline: $setup.selectedTag != tag.name
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(tag.name) + " (" + _toDisplayString(tag.numOfProducts) + ")", 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["onClick", "outline"]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_ion_spinner)
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_slides, { featuredProducts: $setup.featuredProducts }, null, 8, ["featuredProducts"]),
              (!$setup.loading && $setup.products)
                ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.products, (product) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                          "size-xs": "6",
                          "size-md": "4",
                          "size-xl": "3",
                          key: product.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_product_card, {
                              product: product,
                              userLoggedIn: $setup.userLoggedIn
                            }, null, 8, ["product", "userLoggedIn"])
                          ]),
                          _: 2
                        }, 1024)), [
                          [_vShow, ($setup.categoryFilter == 'all' || $setup.categoryFilter == product.categoryId) &&
                            ($setup.selectedTag == 'all' || product.tags.split(' , ').includes($setup.selectedTag))]
                        ])
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}